import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../NavigationBar';
import BottomHalf from './BottomHalf';
import TopHalf from './TopHalf';
import useCurrentUser from '../Authentication/currentUser';
import FeedbackButtonWithForm from '../FeedbackButtonWithForm';
import useAuthAndEmailVerification from '../Authentication/useAuthandEmailVerification';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import DownloadBanner from './DownloasAppBanner';
import { VerificationBanner } from './VerificationBanner';
import ChallengeBanner from './ChallengeBanner';

const Dashboard = () => {
  const { isAuthenticated, isEmailVerified } = useAuthAndEmailVerification();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [showVerificationBanner, setShowVerificationBanner] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('checking');

  useEffect(() => {
    if (isAuthenticated && !isEmailVerified) {
      setShowVerificationBanner(true);
    } else {
      setShowVerificationBanner(false);
    }

    const checkSubscription = async () => {
      if (isAuthenticated && currentUser) {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          const idToken = await user.getIdToken();
          const response = await axios.post(
            'https://klorah-fast-server-9266fe8d441a.herokuapp.com/check-and-update-subscription',
            {},
            {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            }
          );

          if (response.data.status === 'success') {
            setSubscriptionStatus('active');
          } else if (response.data.status === 'no_subscription') {
            setSubscriptionStatus('inactive');
          } else {
            setSubscriptionStatus('error');
          }
        } catch (error) {
          console.error('Error checking subscription:', error);
          setSubscriptionStatus('error');
        }
      }
    };

    checkSubscription();
  }, [isAuthenticated, isEmailVerified, currentUser]);

  const initiateStripeOnboarding = () => {
    navigate('/stripe-ob');
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <View>
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <NavigationBar />
      </div>
      {showVerificationBanner && <VerificationBanner />}
      {currentUser && !currentUser.stripeOnboarded && (
        <div className="flex items-center justify-center gap-x-6 bg-red-600 px-6 py-2.5 sm:px-3.5">
          <p className="text-sm leading-6 text-white text-center">
            <a onClick={initiateStripeOnboarding}>
              <strong className="font-semibold">Additional information required</strong>
              <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              You will need to provide more information to start accepting payments&nbsp;<span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      )}
      {/* {subscriptionStatus === 'checking' && (
        <div className="bg-blue-100 text-blue-700 px-4 py-2">
          Checking your subscription status...
        </div>
      )}
      {subscriptionStatus === 'error' && (
        <div className="bg-yellow-100 text-yellow-700 px-4 py-2">
          There was an error checking your subscription. Please try refreshing the page.
        </div>
      )}
      {subscriptionStatus === 'inactive' && (
        <div className="bg-red-100 text-red-700 px-4 py-2">
          Your subscription is not active. <a href="/pricing" className="underline">Upgrade now</a>
        </div>
      )} */}
      <View style={styles.topFrameContainer}>
        <TopHalf style={styles.topHalf} />
        <BottomHalf style={styles.bottomHalf} />
      </View>
      <FeedbackButtonWithForm />
      <VerificationBanner />
      <ChallengeBanner />
    </View>
  );
};

const styles = StyleSheet.create({
  topFrameContainer: {
    position: 'relative',
    top: 20,
    alignItems: 'center',
    width: '100%',
    gap: 5,
  },
  topHalf: {
    marginBottom: 5,
  },
  bottomHalf: {
    marginTop: -5,
  },
});

export default Dashboard;