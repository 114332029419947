import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default function ChallengeBanner() {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasChallengeDocument, setHasChallengeDocument] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const checkUserStatus = async () => {
            const user = auth.currentUser;
            if (user) {
                const challengeRef = doc(db, 'challengeProgress', user.uid);
                const challengeDoc = await getDoc(challengeRef);
                setHasChallengeDocument(challengeDoc.exists());
                setIsVisible(true);
            } else {
                setIsVisible(true);
            }
            setIsLoading(false);
        };

        checkUserStatus();
    }, [auth, db]);

    if (isLoading || !isVisible) {
        return null;
    }

    const handleStartChallengeClick = () => {
        mixpanel.track("Challenge Action Clicked", { 
            "Source": "Challenge Banner",
            "Action": hasChallengeDocument ? "Go to Dashboard" : "Start Challenge"
        });
        navigate(hasChallengeDocument ? '/challenge-dashboard' : '/challenge-setup');
    }

    const handleDismissClick = () => {
        setIsVisible(false);
        mixpanel.track("Challenge Dismissed", { "Source": "Challenge Banner" });
    }

    return (
        <div className="pointer-events-none fixed bottom-0 left-0 px-6 pb-6">
            <div className="pointer-events-auto max-w-xl rounded-xl bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 p-6 shadow-lg ring-1 ring-orange-900/10">
                <p className="text-sm leading-5 text-white">
                    <span className="block text-lg font-bold mb-2">
                        {hasChallengeDocument ? "🚀 Your 30-Day Challenge is Ongoing!" : "🚀 30-Day Klorah Creator Challenge"}
                    </span>
                    {hasChallengeDocument ? (
                        "Keep up the great work! Continue building your profile, attracting clients, and growing your business. Check your progress and complete today's tasks."
                    ) : (
                        "Boost your freelance career! Join our challenge to build your profile, attract clients, and grow your business."
                    )}
                </p>
                <div className="mt-4 flex items-center gap-x-5">
                    <button
                        onClick={handleStartChallengeClick}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 shadow-sm hover:bg-orange-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                        {hasChallengeDocument ? "Go to Challenge Dashboard" : "Start Challenge"}
                    </button>
                    <button
                        type="button"
                        className="text-sm font-semibold leading-6 text-white hover:text-orange-100"
                        onClick={handleDismissClick}
                    >
                        {hasChallengeDocument ? "Hide" : "Maybe Later"}
                    </button>
                </div>
            </div>
        </div>
    )
}