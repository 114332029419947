import React, { useState, useEffect } from 'react';
import { 
  motion, 
  useScroll, 
  useTransform, 
  useMotionValue, 
  useVelocity, 
  useSpring, 
  useAnimationFrame 
} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase, faRocket } from '@fortawesome/free-solid-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import { ArrowRight } from "lucide-react";

const headerTexts = [
  'Simplify Your Client Billing',
  'Empower Your Freelance Career',
  'Streamline Your Workflow',
  'Join the Freelance Revolution',
];

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const ParallaxContainer = ({ children, baseVelocity = 20 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
    >
      <FontAwesomeIcon icon={icon} size="2x" className="mb-4" />
      <h4 className="text-xl font-bold mb-2">{title}</h4>
      <p>{description}</p>
    </motion.div>
  );
};

export default function LandingPage() {
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  return (
    <div className="min-h-screen bg-white dark:bg-black text-black dark:text-white">
      <div className="sticky top-0 z-50">
        <NavigationBar />
      </div>
      
      <main className="container mx-auto px-4">
        <section className="flex flex-col items-center justify-center text-center py-20" style={{ minHeight: '80vh' }}>
          <div className="h-[120px] flex items-center justify-center mb-6">
            <h1 className="text-5xl md:text-7xl font-bold">
              {typedText}
              <span className="animate-pulse">|</span>
            </h1>
          </div>
          <p className="text-2xl mb-8">Your new client billing solution powered by AI</p>
          <motion.div
            className="relative"
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-br from-green-400 to-green-600 rounded-lg shadow-lg"
              initial={false}
              animate={isHovered ? { scale: 1.05, rotate: -3, y: -5 } : { scale: 1, rotate: 0, y: 0 }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
            />
            <motion.button
              className="relative px-8 py-4 bg-gradient-to-br from-green-500 to-green-600 text-white font-bold text-lg rounded-lg shadow-lg border-b-4 border-green-700"
              whileHover={{ scale: 1.05, rotate: 3, y: -5 }}
              whileTap={{ scale: 0.95, y: 0, borderBottomWidth: '2px' }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              onClick={() => window.location.href='/onboarding-selection'}
            >
              <span className="mr-2">Get Started</span>
              <motion.span
                initial={{ x: 0 }}
                animate={isHovered ? { x: 5 } : { x: 0 }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              >
                <ArrowRight className="inline-block h-5 w-5" />
              </motion.span>
            </motion.button>
          </motion.div>
          <motion.p
            className="mt-4 text-green-700 font-medium"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            No credit card needed
          </motion.p>
          <div className="mt-12 w-full max-w-[1200px] mx-auto">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <iframe 
                src="https://www.youtube.com/embed/WJLuaGxR-6c?si=cYxdHc8cAciQIQOI&autoplay=1&mute=1&vq=hd1080" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
              ></iframe>
            </div>
          </div>
        </section>

        <ParallaxContainer>Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive</ParallaxContainer>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center">Why Choose Klorah?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard 
              icon={faFileInvoice} 
              title="Effortless Invoicing" 
              description="Create and send professional invoices in seconds."
            />
            <FeatureCard 
              icon={faFileContract} 
              title="Smart Agreements" 
              description="Generate legally-sound agreements quickly with our AI tools."
            />
            <FeatureCard 
              icon={faUserCircle} 
              title="Freelancer Profiles" 
              description="Showcase your work with a stunning profile and attract new clients."
            />
            <FeatureCard 
              icon={faBriefcase} 
              title="Bookable Services" 
              description="Allow clients to book your services directly through your profile."
            />
          </div>
        </motion.section>
           
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20 bg-black text-white dark:bg-gray-900 dark:text-gray-200 rounded-xl my-20 shadow-2xl overflow-hidden"
        >
          <div className="container mx-auto px-6">
            <h2 className="text-4xl md:text-5xl font-extrabold mb-12 text-center">Klorah Creator Challenge</h2>
            <div className="max-w-3xl mx-auto space-y-8">
              <h3 className="text-3xl md:text-4xl font-bold">30 Days to Transform Your Career</h3>
              <p className="text-lg leading-relaxed">
                Join our intensive 30-day program designed to kickstart your freelance career. Get clients in 30 days or your money back!
              </p>
              <ul className="space-y-4">
                {[
                  { icon: faRocket, text: "Daily tasks and expert guidance" },
                  { icon: faUserCircle, text: "Build a standout profile" },
                  { icon: faBriefcase, text: "Connect with potential clients" },
                  { icon: faFileInvoice, text: "Full access to all Klorah tools" }
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-lg">
                    <FontAwesomeIcon icon={item.icon} className="mr-3 text-2xl text-yellow-300" />
                    {item.text}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white text-orange-600 dark:bg-gray-800 dark:text-white py-3 px-8 rounded-md text-lg font-semibold shadow-lg transition-transform duration-300 hover:bg-orange-100 dark:hover:bg-gray-700"
                  onClick={() => window.location.href='/creator-challenge'}
                >
                  Learn More
                </motion.button>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center">Pricing</h2>
          <div className="flex flex-wrap justify-center items-center gap-10">
            <div className="flex flex-col bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-3 text-center">Free Plan</h3>
              <h4 className="text-xl font-medium mb-1 text-center">$0/month</h4>
              <p className="text-center text-gray-500 dark:text-gray-400 mb-6">3.3% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-6">
                <li>Unlimited access to Klorah community</li>
                <li>Basic AI agreements</li>
                <li>Customer support</li>
                <li>Access to new features and updates</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-black text-white dark:bg-white dark:text-black py-3 px-6 rounded-lg"
                onClick={() => window.location.href='/onboarding-selection'}
              >
                Get Started Now
              </motion.button>
            </div>
            <div className="flex flex-col bg-black text-white dark:bg-black dark:text-white p-8 rounded-lg shadow-xl w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-3 text-center">Pro Plan</h3>
              <h4 className="text-xl font-medium mb-1 text-center">$15/month</h4>
              <p className="text-center text-gray-400 dark:text-gray-400 mb-6">2.9% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-6">
                <li>Cutting-edge invoicing capabilities</li>
                <li>AI-generated or seamlessly imported client agreements</li>
                <li>Exclusive professional-grade tools</li>
                <li>Early access to new products and features</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-white text-black dark:bg-white dark:text-black py-3 px-6 rounded-lg"
                onClick={() => window.location.href='/onboarding-selection'}
              >
                Get Started Now
              </motion.button>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-20"
        >
          <h2 className="text-4xl font-bold mb-6">Join the Freelance Revolution</h2>
          <p className="text-2xl mb-8">Klorah isn't just a tool—it's a community of freelancers changing the way they work. Join us and experience financial freedom.</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-green-500 text-white py-3 px-6 rounded-lg text-xl"
            onClick={() => window.location.href='/onboarding-selection'}
          >
            Sign Up for Free
          </motion.button>
        </motion.section>
      </main>
      <Footer />
    </div>
  );
}