import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db, storage } from '../Firebase';
import { collection, query, where, getDocs, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { toast } from 'react-toastify';
import ServiceModal from '../Profile/ServiceModal';
import ServiceList from '../Profile/ServiceList';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';

export default function ProfileEditor({ isEditing = false }) {
  const initialProfile = {
    name: '',
    username: '',
    bio: '',
    professionalTitle: '',
    skills: [],
    personalWebsite: '',
    contactInfo: { email: '', phone: '', location: '' },
    socialMediaLinks: { instagram: '', linkedin: '', twitter: '', facebook: '', other: '' },
    portfolio: [],
    profilePicture: '',
    services: []
  };

  const [profile, setProfile] = useState(null);
  const [preview, setPreview] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [newService, setNewService] = useState({ name: '', description: '', price: '', currency: 'USD' });
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState(null);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [portfolio, setPortfolio] = useState([]);
  const [newPortfolioItem, setNewPortfolioItem] = useState({ title: '', description: '', image: '' });
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const userId = auth.currentUser?.uid;
      if (userId) {
        const profileRef = doc(db, 'profiles', userId);
        const profileDoc = await getDoc(profileRef);
        if (profileDoc.exists()) {
          const profileData = profileDoc.data();
          setProfile(prevProfile => ({
            ...initialProfile, // Use initialProfile as a base
            ...profileData,
            contactInfo: { ...initialProfile.contactInfo, ...profileData.contactInfo },
            socialMediaLinks: { ...initialProfile.socialMediaLinks, ...profileData.socialMediaLinks },
            portfolio: profileData.portfolio || [],
            services: profileData.services || [],
          }));
          if (profileData.profilePicture) {
            setPreview(profileData.profilePicture);
          }
          setPortfolio(profileData.portfolio || []);
        }
      }
      setIsLoading(false);
    };
    fetchProfile();
  }, [auth]);

  useEffect(() => {
    const checkUsername = async () => {
      if (profile?.username && usernameChanged) {
        const isUnique = await checkUsernameUnique(profile.username);
        setUsernameAvailable(isUnique);
      }
    };
    checkUsername();
  }, [profile?.username, usernameChanged]);

  const checkUsernameUnique = async (username) => {
    const usersRef = collection(db, 'profiles');
    const q = query(usersRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }
    handleProfilePictureChange(e);
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const userId = auth.currentUser.uid;
    const storageRef = ref(storage, `profilePictures/${userId}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);

    setProfile((prevProfile) => ({ ...prevProfile, profilePicture: photoURL }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value
    }));
    if (name === 'username') {
      setUsernameChanged(true);
    }
  };

  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      contactInfo: { ...prevProfile.contactInfo, [name]: value }
    }));
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      socialMediaLinks: { ...prevProfile.socialMediaLinks, [name]: value }
    }));
  };

  const handleSkillChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim()).filter(Boolean);
    setProfile(prevProfile => ({ ...prevProfile, skills }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = auth.currentUser.uid;

    if (!usernameAvailable) {
      toast.error('Username is already taken.');
      return;
    }

    try {
      const cleanedProfile = {
        ...profile,
        portfolio: portfolio,
        services: profile.services.filter(service => service.name || service.description || service.price)
      };

      await setDoc(doc(db, 'profiles', userId), cleanedProfile);
      toast.success(isEditing ? 'Profile updated successfully!' : 'Profile setup complete!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(`Error ${isEditing ? 'updating' : 'setting up'} profile. Please try again.`);
    }
  };

  const handleAddService = async () => {
    const userId = auth.currentUser.uid;
    const profileRef = doc(db, 'profiles', userId);
    await updateDoc(profileRef, { services: [...profile.services, newService] });
    setProfile((prev) => ({ ...prev, services: [...prev.services, newService] }));
    setShowServiceModal(false);
  };

  const deleteService = async (serviceToDelete) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      const updatedServices = profile.services.filter((service) => service !== serviceToDelete);
      const userId = auth.currentUser.uid;
      const profileRef = doc(db, 'profiles', userId);
      await updateDoc(profileRef, { services: updatedServices });
      setProfile((prev) => ({ ...prev, services: updatedServices }));

      if (serviceToDelete.image) {
        const storageRef = ref(storage, serviceToDelete.image);
        await deleteObject(storageRef);
      }
    }
  };

  const handleServiceImageUpload = async (e, service) => {
    const file = e.target.files[0];
    if (!file) return;

    const userId = auth.currentUser.uid;
    const storageRef = ref(storage, `serviceImages/${service.name}_${userId}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);

    const updatedServices = profile.services.map((s) => {
      if (s === service) {
        return { ...s, image: imageUrl };
      }
      return s;
    });

    const profileRef = doc(db, 'profiles', userId);
    await updateDoc(profileRef, { services: updatedServices });
    setProfile((prev) => ({ ...prev, services: updatedServices }));
  };

  const handleAddPortfolioItem = () => {
    if (newPortfolioItem.title && newPortfolioItem.description) {
      setPortfolio([...portfolio, newPortfolioItem]);
      setNewPortfolioItem({ title: '', description: '', image: '' });
    }
  };

  const handleRemovePortfolioItem = (index) => {
    setPortfolio(portfolio.filter((_, i) => i !== index));
  };

  const handlePortfolioItemChange = (e, field) => {
    setNewPortfolioItem({ ...newPortfolioItem, [field]: e.target.value });
  };

  const handlePortfolioImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const userId = auth.currentUser.uid;
    const storageRef = ref(storage, `portfolioImages/${userId}/${file.name}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);

    setNewPortfolioItem({ ...newPortfolioItem, image: imageUrl });
  };

  if (isLoading || profile === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 text-white p-6">
      <div className="bg-white dark:bg-black rounded-2xl p-8 shadow-lg w-full max-w-2xl text-black dark:text-gray-200 overflow-y-auto max-h-[90vh]">
        <h2 className="text-2xl font-bold mb-4">
          {isEditing ? 'Edit Your Profile' : 'Create a Client Shareable Profile'}
        </h2>
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className="flex flex-col items-center">
                <div className="relative w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
                  {preview ? (
                    <img src={preview} alt="Profile Preview" className="w-full h-full object-cover" />
                  ) : (
                    <div className="flex items-center justify-center h-full bg-gray-200">
                      <span className="text-gray-500">No image</span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="profilePicture"
                    accept="image/*"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleFileChange}
                  />
                </div>
                <label htmlFor="profilePicture" className="mt-2 text-sm text-gray-700 cursor-pointer underline">
                  Change Profile Picture
                </label>
              </div>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={profile.name || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded mt-4 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={profile.username || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded my-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              {!usernameAvailable && <p className="text-red-500 text-xs">Username is already taken.</p>}
              <textarea
                name="bio"
                placeholder="Bio"
                value={profile.bio || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded my-2 bg-gray-100 dark:bg-black dark:text-white"
                rows="3"
              ></textarea>
              <input
                type="text"
                name="professionalTitle"
                placeholder="Professional Title"
                value={profile.professionalTitle || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded my-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="skills"
                placeholder="Skills (comma-separated)"
                value={profile.skills ? profile.skills.join(', ') : ''}
                onChange={handleSkillChange}
                className="w-full p-2 border rounded my-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-black dark:bg-white text-white dark:text-black font-semibold rounded-md hover:bg-gray-800 dark:hover:bg-gray-300 transition duration-300"
                onClick={() => setCurrentStep(2)}
              >
                Next
              </button>
            </>
          )}
          {currentStep === 2 && (
            <>
              <h4 className="text-lg font-bold mb-2">Contact Information</h4>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={profile.contactInfo?.email || ''}
                onChange={handleContactInfoChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={profile.contactInfo?.phone || ''}
                onChange={handleContactInfoChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="location"
                placeholder="Location"
                value={profile.contactInfo?.location || ''}
                onChange={handleContactInfoChange}
                className="w-full p-2 border rounded bg-gray-100 dark:bg-black dark:text-white"
              />
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-black dark:bg-white text-white dark:text-black font-semibold rounded-md hover:bg-blue-600 dark:hover:bg-gray-300 transition duration-300"
                onClick={() => setCurrentStep(3)}
              >
                Next
              </button>
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 font-semibold rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
                onClick={() => setCurrentStep(1)}
              >
                Previous
              </button>
            </>
          )}
          {currentStep === 3 && (
            <>
              <h4 className="text-lg font-bold mb-2">Social Media Links</h4>
              <input
                type="text"
                name="instagram"
                placeholder="https://www.instagram.com/yourusername"
                value={profile.socialMediaLinks?.instagram || ''}
                onChange={handleSocialMediaChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="linkedin"
                placeholder="https://www.linkedin.com/in/yourusername"
                value={profile.socialMediaLinks?.linkedin || ''}
                onChange={handleSocialMediaChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="twitter"
                placeholder="https://www.twitter.com/yourusername"
                value={profile.socialMediaLinks?.twitter || ''}
                onChange={handleSocialMediaChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="facebook"
                placeholder="https://www.facebook.com/yourusername"
                value={profile.socialMediaLinks?.facebook || ''}
                onChange={handleSocialMediaChange}
                className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
              />
              <input
                type="text"
                name="other"
                placeholder="https://www.yourwebsite.com"
                value={profile.socialMediaLinks?.other || ''}
                onChange={handleSocialMediaChange}
                className="w-full p-2 border rounded bg-gray-100 dark:bg-black dark:text-white"
              />
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-black text-white dark:bg-white dark:text-black font-semibold rounded-md hover:bg-gray-800 dark:hover:bg-gray-300 transition duration-300"
                onClick={() => setCurrentStep(4)}
              >
                Next
              </button>
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 font-semibold rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
                onClick={() => setCurrentStep(2)}
              >
                Previous
              </button>
            </>
          )}
          {currentStep === 4 && (
            <>
              <h3 className="text-2xl font-bold mb-4 mt-8 text-center">Add services your clients can book</h3>
              <ServiceList
                services={profile.services}
                handleAddService={() => setShowServiceModal(true)}
                handleServiceClick={(service) => console.log(service)}
                deleteService={deleteService}
                handleServiceImageUpload={handleServiceImageUpload}
                hoveredServiceIndex={hoveredServiceIndex}
                setHoveredServiceIndex={setHoveredServiceIndex}
                isAuthenticated={true}
              />
              {showServiceModal && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white dark:bg-gray-900 text-black dark:text-gray-200 rounded-lg p-6 max-w-md w-full">
                    <ServiceModal
                      newService={newService}
                      setNewService={setNewService}
                      addService={handleAddService}
                    />
                    <button
                      className="w-full py-2 mt-4 bg-red-600 text-white rounded hover:bg-red-700"
                      onClick={() => setShowServiceModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-black text-white dark:bg-white dark:text-black font-semibold rounded-md hover:bg-gray-800 dark:hover:bg-gray-300 transition duration-300"
                onClick={() => setCurrentStep(5)}
              >
                Next
              </button>
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 font-semibold rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
                onClick={() => setCurrentStep(3)}
              >
                Previous
              </button>
            </>
          )}
          {currentStep === 5 && (
            <>
              <h4 className="text-lg font-bold mb-2">Portfolio</h4>
              <div className="max-h-[50vh] overflow-y-auto mb-4">
                {portfolio.map((item, index) => (
                  <div key={index} className="mb-4 p-4 border rounded-lg relative">
                    <h5 className="font-bold">{item.title}</h5>
                    <p>{item.description}</p>
                    {item.image && <img src={item.image} alt={item.title} className="mt-2 max-w-full h-auto" />}
                    <button
                      onClick={() => handleRemovePortfolioItem(index)}
                      className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Portfolio Item Title"
                  value={newPortfolioItem.title}
                  onChange={(e) => handlePortfolioItemChange(e, 'title')}
                  className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
                />
                <textarea
                  placeholder="Portfolio Item Description"
                  value={newPortfolioItem.description}
                  onChange={(e) => handlePortfolioItemChange(e, 'description')}
                  className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
                  rows="3"
                ></textarea>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePortfolioImageUpload}
                  className="w-full p-2 border rounded mb-2 bg-gray-100 dark:bg-black dark:text-white"
                />
                <button
                  type="button"
                  onClick={handleAddPortfolioItem}
                  className="w-full py-2 px-4 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300 flex items-center justify-center"
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Add Portfolio Item
                </button>
              </div>
              <button
                type="submit"
                className="w-full py-3 px-4 mt-4 bg-black text-white dark:bg-white dark:text-black font-semibold rounded-md hover:bg-gray-800 dark:hover:bg-gray-300 transition duration-300"
              >
                Save and Continue
              </button>
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 font-semibold rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
                onClick={() => navigate('/membership-billing')}
              >
                Skip for Now
              </button>
              <button
                type="button"
                className="w-full py-3 px-4 mt-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 font-semibold rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
                onClick={() => setCurrentStep(4)}
              >
                Previous
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}