import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AutoLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    if (initialCheckDone || location.pathname === '/contact-us') {
      return;
    }

    const remember = localStorage.getItem('rememberMe');
    if (remember && remember === 'true') {
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          const isOnboardingComplete = localStorage.getItem('isOnboardingComplete');

          const pathsToIgnore = [
            '/share-agreement/',
            '/edit-agreement/',
            '/contact-us',
            '/profile',
            '/payment/',
            '/signature/',
            '/client-signature/',
            '/tos',
            '/privacy-policy',
            '/blogs',
            '/subscription',
            '/create-agreement',
            '/test-payment-confirmation/', 
            '/payment-confirmation/',
            '/challenge-dashboard',
          ];
          
          const shouldNavigate = !pathsToIgnore.some(path => location.pathname.startsWith(path));

          if (shouldNavigate) {
            if (!isOnboardingComplete) {
              navigate('/dashboard');
            } else {
              navigate('/dashboard');
            }
          }

          setInitialCheckDone(true);
        }
      });

      return () => unsubscribe();
    }
  }, [navigate, auth, initialCheckDone, location.pathname]);

  return null;
};

export default AutoLogin;
